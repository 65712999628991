<template>
  <div class="wechatpayBody">
    <van-overlay :show="showCode" @click="showCode = false">
      <div class="wrapperCode" @click.stop>
        <div v-show="codeImg">
          <div style="width:100%;text-align: center;" >长按点击扫描二维码关注公众号！</div>
          <!-- <img src="../../public/img/code.png"  alt=""> -->
          <img src="../../public/img/codeIot.png"  alt="">
          <div style="width:100%;text-align: center;">关注后重新扫描二维码！</div>
        </div>
        
      </div>
    </van-overlay>
    <div class="wechatUserTitle">
      <div>
        <img style="width: 80%; height: 80%" :src="wechatUser.wImg" alt="" />
      </div>
      <div>
        <div style="text-align: left; width: 100%; line-height: 30px">
          {{ wechatUser.wName }}
        </div>
        <div style="text-align: left; width: 100%">
          <div v-if="payParam.payType == 1">
            余额：<span style="font-size: 16px">{{ wechatUser.wBalance }}</span>
          </div>
          <div
            v-if="payParam.payType == 1"
            @click="recharge"
            style="
              text-align: center;
              font-weight: bolder;
              margin: 10px 0;
              color: green;
            "
          >
            充值
          </div>
          <van-overlay :show="show" @click="show = false">
            <div class="wrapper" @click="show = false">
              <div class="rechargeBox" @click.stop>
                <div>
                  <div>充值金额:</div>
                  <el-radio-group v-model="money" style="width: 100%">
                    <div class="moneyBox">
                      <div>
                        <el-radio-button size="medium" :label="0.01"
                          >0.01元</el-radio-button
                        >
                      </div>
                      <div>
                        <el-radio-button size="medium" :label="30"
                          >30元</el-radio-button
                        >
                      </div>
                      <div>
                        <el-radio-button size="medium" :label="50"
                          >50元</el-radio-button
                        >
                      </div>
                      <div>
                        <el-radio-button size="medium" :label="100"
                          >100元</el-radio-button
                        >
                      </div>
                    </div>
                  </el-radio-group>
                </div>
                <div class="footer">
                  <van-button @click="topPay" size="large" round type="primary"
                    >立即支付</van-button
                  >
                </div>
              </div>
            </div>
          </van-overlay>
        </div>
      </div>
      <div>
        <div
          style="
            color: rgb(242, 151, 102);
            text-align: center;
            font-weight: bolder;
          "
          @click="$router.push(`/PayHistory?openId=${openId}`)"
        >
          消费详情
        </div>
      </div>
    </div>
    <div :style="{ background: imgUrl, backgroundSize: size }">
      <div>
        <van-radio-group v-model="checked" @change="getPrice">
          <div class="commodityBox">
            <div
              class="commodity"
              v-for="(item, index) in payParam.payParamMoneyList"
              @click="setCheck(item.payId)"
              :key="index"
            >
              <div class="check">
                <div style="width: 30px; height: 20px">
                  <van-radio :name="item.payId"></van-radio>
                </div>
                <div>{{ item.payName }}</div>
              </div>
              <div style="width: 100px"><div v-if="payParam.paySetMeal != 2"> ￥{{ item.moneyNum }}元</div></div>
              <div style="width: 130px">
                <div  v-if="payParam.paySetMeal == 0">
                  <el-input-number
                    @input="getPrice"
                    size="mini"
                    v-model="item.sendValue"
                    :precision="0"
                    :min="0"
                  ></el-input-number>
                </div>
              </div>
            </div>
          </div>
        </van-radio-group>
      </div>

      <div class="footer">
        <div class="price" v-if="price">￥{{ price }}元</div>
        <van-button @click="payfor" size="large" round type="primary"
          >立即支付</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { APP_ID } from "@/util/global.js";
import { Dialog } from "vant";
import { OSS_URL, wechatimg_IMG } from "../util/constant";
let that;
export default {
  data() {
    return {
      codeImg:false,
      size: "",
      imgUrl: "#fff",
      money: 0,
      price: "",
      show: false,
      checked: "",
      code: "",
      openId: "",
      notifyUrl: "",
      wechatName: "",
      wechatUser: {},
      payParam: {},
      payParamFrom: {},
      showCode: true,
    };
  },
  mounted() {
    that = this;
    that.getCode();
    that.getPayParam();
  },
  methods: {
  // setImg(e){
  //   let img=e.target.dataset.src;
  //   wx.previewImage({
  //     current:img,
  //     urls:[img]
  //   })
  // },
    getPrice() {
      if (that.payParam.payParamMoneyList.length > 0) {
        let item = that.payParam.payParamMoneyList.filter((item) => {
          return item.payId == that.checked;
        })[0];
        that.price =
          that.payParam.paySetMeal == 0
            ? item.moneyNum * item.sendValue
            : item.moneyNum;
        if (!that.price) {
          that.price=0;
        }
        that.price = that.price.toFixed(2);
      }
    },
    recharge() {
      that.money = 0;
      that.show = true;
    },
    topPay() {
      if (that.money) {
        let topPayFrom = {
          wechatOpenId: that.openId,
          total: that.money * 100,
        };
        that.$api.payParam.topPay(topPayFrom).then((res) => {
          window.WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            res.data,
            function (response) {
              that.show = false;
              that.getBalance();
              that.getPayParamList();
              if (response.err_msg == "get_brand_wcpay_request:ok") {
                that.$message({
                  type: "success",
                  message: "支付成功",
                });
              } else {
                that.$message({
                  type: "warning",
                  message: "支付失败",
                });
              }
            }
          );
        });
      }
    },
    // 解析code
    getUrlKey: function (name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [",", ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    // 发送微信请求获取带code的页面
    getCodeApi: function (state) {
      //获取code
      //   let urlNow = encodeURIComponent(window.location.href);
      let urlNow = encodeURIComponent(window.location.href);
      //   console.log(urlNow);
      let scope = "snsapi_base"; //snsapi_userinfo   //静默授权 用户无感知
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
      window.location.replace(url);
    },
    // 获取页面code
    getCode: function () {
      let code = that.getUrlKey("code"); //获取url参数code
      if (code) {
        that.code = code;
      } else {
        that.getCodeApi(
          that.$route.query.payParamId + "-" + that.$route.query.dtuSn
        );
      }
    },
    // 获取页面数据
    getPayParamList() {
      that.$api.payParam.getPayParam(that.payParamFrom).then((res) => {
        if (res.code == 200) {
          that.payParam = res.data;
          if (!that.payParam.imgUrl) {
            that.imgUrl = "#fff";
            that.size = "";
          } else {
            that.imgUrl = `url(${
              OSS_URL + wechatimg_IMG + that.payParam.imgUrl
            }) no-repeat`;
            that.size = "100% 100%";
          }
          if (that.payParam.paySetMeal == 0) {
            that.payParam.payParamMoneyList.forEach((element) => {
              element.sendValue = 1;
            });
          }
        }
      });
    },
    getPayCheck(openId){
        that.$api.payParam.getPayCheck(openId).then((res) => {
        if (res.code == 200) {
            if (res.data.subscribe == 0) {
            that.codeImg=true;
            that.showCode=true;
            }else{
              that.codeImg=false;
              that.showCode=false;
            }
        }
      });
    },
    // 是否获取页面数据
    getPayParam() {
      let id = that.getUrlKey("state");
      // let id = "1-121100000010";
      if (id) {
        if (!window.localStorage.getItem("WechatpayOpanId")) {
          that.getOpen();
        } else {

          that.openId = window.localStorage.getItem("WechatpayOpanId");
          that.notifyUrl = window.localStorage.getItem("WechatpayNotifyUrl");
          that.wechatName = window.localStorage.getItem("WechatpayWechatName");
          that.getPayCheck(that.openId)
          that.getBalance();
        }
        let arrId = id.split("-");
        let obj = {
          payParamId: arrId[0],
          dtuSn: arrId[1],
        };
        that.payParamFrom = obj;
        that.getPayParamList();
      } else {
        window.localStorage.setItem("WechatpayOpanId", "");
        window.localStorage.setItem("WechatpayNotifyUrl", "");
        window.localStorage.setItem("WechatpayWechatName", "");
      }
    },
    // 获取openId
    getOpen: function () {
      that.$api.iccid
        .getOpenId(that.code)
        .then((response) => {
          if (response.code == 200) {
            
            window.localStorage.setItem(
              "WechatpayOpanId",
              response.data.openId
            );
            window.localStorage.setItem(
              "WechatpayNotifyUrl",
              response.data.notifyUrl
            );
            window.localStorage.setItem(
              "WechatpayWechatName",
              response.data.wechatName
            );
            that.openId = response.data.openId;
            that.notifyUrl = response.data.notifyUrl;
            that.wechatName = response.data.wechatName;
            if (response.data.subscribe == 0) {
             that.codeImg=true;
            }else{
              that.codeImg=false;
              that.showCode=false;
            }
            that.getBalance();
          }
        })
        .catch((c) => {
          alert(JSON.stringify(c));
        });
    },
    // 支付接口
    payfor: function () {
      if (!that.checked) {
        return;
      }

      let item = that.payParam.payParamMoneyList.filter((item) => {
        return item.payId == that.checked;
      })[0];

      let total =
        that.payParam.paySetMeal == 0
          ? item.moneyNum * item.sendValue * 100
          : item.moneyNum * 100;
      total = total.toFixed(0) * 1;
      let payForm = {
        description: item.payName,
        total: total,
        dtuSn: that.payParam.dtuSn,
        wechatOpenId: that.openId,
        sendValue: item.sendValue,
        payParamId: 1,
        planId: that.payParam.planId,
        payId: item.payId,
      };
      if (that.payParam.payType == 0) {
        that.$api.payParam
          .jsapiPay(payForm)
          .then((response) => {
            if (response.code == 200) {
              // console.log(response);
              if (that.payParam.payType == 0) {
                window.WeixinJSBridge.invoke(
                  "getBrandWCPayRequest",
                  response.data,
                  function (response) {
                    that.getPayParamList();
                    that.getBalance();
                    if (response.err_msg == "get_brand_wcpay_request:ok") {
                      that.$message({
                        type: "success",
                        message: "支付成功",
                      });
                    } else {
                      that.$message({
                        type: "warning",
                        message: "支付失败",
                      });
                    }
                  }
                );
              }
            }
          })
          .catch((c) => {
            alert(JSON.stringify(c));
          });
      } else if (that.payParam.payType == 1) {
        Dialog.confirm({
          message: "确认支付吗？",
        }).then(() => {
          that.$api.payParam
            .jsapiPay(payForm)
            .then((response) => {
              if (response.code == 200) {
                that.getPayParamList();
                that.getBalance();

                that.$message({
                  type: "success",
                  message: "支付成功",
                });
              } else {
              }
            })
            .catch((c) => {
              alert(JSON.stringify(c));
            });
        });
      }
    },
    // 获取用户信息
    getBalance() {
      // that.openId
      that.$api.payParam.getBalance(that.openId).then((res) => {
        if (res.code == 200) {
          that.wechatUser = res.data;
          that.wechatUser.wBalance = (that.wechatUser.wBalance / 100).toFixed(
            2
          );
        }
      });
    },
    // 点击选中
    setCheck(id) {
      that.checked = id;
      that.getPrice();
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.wechatpayBody {
  width: 100%;
  height: 100%;
}
.wechatUserTitle {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  background: #fff;
}
.wechatUserTitle > div {
  width: 30vw;
  height: 30vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.wechatUserTitle div {
  font-size: 16px;
}
.commodityBox {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 30vw - 80px);
  box-sizing: border-box;
  padding: 15px 0;
}
/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
.commodityBox::-webkit-scrollbar {
  width: 10px;
  /*对垂直流动条有效*/
  height: 10px;
  /*对水平流动条有效*/
}

/*定义滑块颜色、内阴影及圆角*/
.commodityBox::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #aaaaaacc;
}
.commodity {
  width: 94%;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5px 3%;
  /* background: #999; */
  border-radius: 12px;
}
.commodity > div {
  font-size: 16px;
  width: 25%;
  text-align: left;
  font-weight: 500;
  color: #000;
}
.footer {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  padding: 5px 5vw;
  box-sizing: border-box;
  position: relative;
}
.price {
  width: 100%;
  height: 20px;
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 16px;
  color: red;
  font-weight: 550;
}
::v-deep .van-button__text {
  font-size: 16px;
}
.check {
  width: 110px;
  display: flex;
  justify-content: right;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.rechargeBox {
  width: 80%;
  height: 70%;
  box-sizing: border-box;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 12px;
}
::v-deep .el-radio-button {
  width: 100%;
}
::v-deep .el-radio-button__inner {
  width: 100%;
  display: inline-block;
}
.moneyBox {
  overflow-x: hidden;
  overflow-y: auto;
}

/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
.moneyBox::-webkit-scrollbar {
  width: 10px;
  /*对垂直流动条有效*/
  height: 10px;
  /*对水平流动条有效*/
}

/*定义滑块颜色、内阴影及圆角*/
.moneyBox::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #aaaaaacc;
}
.moneyBox > div {
  margin: 10px 0;
}
.van-overlay {
  z-index: 9999;
}
::v-deep .el-input__inner {
  font-size: 15px;
}
.wrapperCode {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.qrcode{
  width: 100%;

}

</style>